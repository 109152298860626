<template>
  <div v-if="!isLoading">
    <!--component :is="getType" /-->
    <welcome-no-community-component/>
  </div>
  <div v-else>
    <b-spinner class="d-block mx-auto mt-3" />
  </div>
</template>

<script>
import WelcomeCommunityComponent from './components/WelcomeCommunityComponent.vue';
import WelcomeNoCommunityComponent from './components/WelcomeNoCommunity.vue';
import { startNotifications } from '@/@core/utils/notifications/notifications';
import { selectLangFromCommunity } from '@/@core/utils/collective';
import { translateTranslationTable } from '@/@core/libs/i18n/utils';
import { mapGetters } from 'vuex'

export default {
  components: {
    WelcomeNoCommunityComponent,
    WelcomeCommunityComponent,
  },
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters(['loggedUser', 'loggedMember']),
    
    collectiveSlug() {
      return this.$route.params.communityId;
    },
    community() {
      return this.$store.getters.currentCollective;
    },
    getType() {
      const spacesWithDetail = [
        'Project', 
        'Event', 
        'Challenge', 
        'Course', 
        'Service', 
        'Organization', 
        'Subcommunity'
      ];
      if (spacesWithDetail.includes(this.$store.getters.currentCollective.mainType)) {
        return 'WelcomeNoCommunityComponent';
      }
      return 'WelcomeCommunityComponent';
    },
  },
  async created() {
    //this.isLoading = true;
    console.log('this.collectiveSlug', this.collectiveSlug);
    await this.initCommunity(this.collectiveSlug);

    await startNotifications({ 
      memberKey: this.loggedMember.key,
      communitySlug: this.collectiveSlug
    });

    this.isLoading = false;

    this.$router.afterEach((to, from) => {
      this.$store.dispatch('addAnalytic', {
        morphType: 'page',
        action: 'enter-page',
        content: to.path,
      });
      if (this.$store.getters.currentCollective) {
        selectLangFromCommunity(this.$store.getters.currentCollective);
      }
    });

    this.$store.dispatch('addAnalytic', {
      morphType: 'page',
      action: 'enter-page',
      content: this.$route.path,
    });
  },
  watch: {
    async collectiveSlug(collectiveSlug) {
      if (collectiveSlug !== this.currentCollectiveSlug) {
        this.initCommunity(collectiveSlug);
      }
    },
  },
  methods: {
    async initCommunity(collectiveSlug) {
      console.log('Welcome - initCommunity - collectiveSlug', collectiveSlug);
      await this.$store.dispatch('initCurrentCommunity', {
        communitySlug: collectiveSlug,
      });

      selectLangFromCommunity(this.$store.getters.currentCollective);

      // Page title
      document.title = this.translate(this.documentTitle);

    },
    translate(field) {
      return translateTranslationTable(this.$store.state.locale.currentLocale, field);
    },
  }
};
</script>
